import CFG from '@/config'
import cookies from 'js-cookie'
export default {install(vm) {
   const typ = {};
   const wait_for_soc = [];
   let ttc = 0, timer, soc;
   const socket = {
      connect: () => {
         const token = cookies.get(CFG.tokenKey);
         if(!token) {
            return console.log('no token, skip connect');
         }
         console.log('Connecting ws...');
         clearTimeout(timer);
         if(!soc || soc.readyState === 3) {
            soc = new WebSocket(CFG.api_url.replace('http','ws'));
            soc.onopen = () => {
               soc.send( JSON.stringify({typ:'login', token: token}) );
            }
            soc.onclose = ev => {
               if(ev.wasClean) return;
               console.log('closing:', ev);
               ttc = ttc+1000||1000;
               if(ttc > 1800000) ttc = 1800000;
               timer = setTimeout(socket.connect, ttc);
            }
            soc.onmessage = message => {
               try {
                  var json = JSON.parse(message.data);
                  if(json.typ === 'acc') {
                     this.soid = json.soid;
                     wait_for_soc.forEach(fn => { fn(soc) });
                  }
                  if(typeof typ[json.typ] === 'function') typ[json.typ](json);
                  else console.log('no listener : ', json);
               } catch (e) {
                  console.log('This doesn\'t look like a valid JSON: ', message.data, e);
               }
            }
            soc.onerror = function(evt) { console.log('something goes wrong :', evt) };
         } else if(soc.readyState === 0) console.log('Connecting to session server ...');
      },
      send(o) {
         const msg = JSON.stringify(o);
         if(soc && soc.readyState === 1) {
            console.log('send', msg);
            soc.send(msg);
         } else {
            console.log('soc not ready');
            const idx = wait_for_soc.push(s => {
               s.send(msg);
               wait_for_soc.splice(idx - 1, 1);
            });
         }
      },
      close() {
         if(soc && soc.readyState === 1) soc.close();
      },
      listen(ev, fn) {
         typ[ev] = fn;
      },
      unlisten(ev) {
         typ[ev] = null;
      }
   }
   vm.prototype.$soc = socket;
}}
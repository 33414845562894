import cookies from 'js-cookie'
import CFG from '@/config'
import { login } from '../api/user'
import axios from 'axios'
// import { router } from '../_helpers';
let sid = cookies.get('x-app');
let token = cookies.get(CFG.tokenKey);
if(!sid) {
    sid = Math.random().toString(36).slice(2);
    cookies.set('x-app', sid);
}
if(token) {
    console.log('token exist');
    axios.defaults.headers.common[CFG.tokenHeaderKey] = token;
}

export default {
    namespaced: true,
    state: {sid: sid, user: {}, token: token, loggingIn: !!token},
    actions: {
        ok({commit}, {token,data}) {
            cookies.set(CFG.tokenKey, token, {expires: 1});
            axios.defaults.headers.common[CFG.tokenHeaderKey] = token;
            commit('set', data);
        },
        login({ commit }, data) {
            return new Promise(res => {
                login({...data, sid: sid}).then(({data, token, ...rsl}) => {
                    console.log('login vuex', rsl);
                    if(token) {
                        axios.defaults.headers.common[CFG.tokenHeaderKey] = token;
                        commit('loginRequest', data);
                    }
                    res(rsl);
                });
            })
        },
        logout({ commit }) {
            delete axios.defaults.headers.common[CFG.tokenHeaderKey]
            cookies.remove(CFG.tokenKey)
            commit('logout');
        }
    },
    mutations: {
        set(state, user) {
            // state.token = token;
            state.loggingIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.loggingIn = false;
            state.user = {};
        },
        logout(state) {
            state.loggingIn = false;
            state.user = {};
        },
        setToken() {
            
        }
    }
}

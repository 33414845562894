export default {
   api_url: process.env.VUE_APP_API_URL || '/api',
   storage_url: process.env.VUE_APP_STORAGE_URL || '/storage',
   tokenKey: 'pes-token',
   tokenHeaderKey: 'x-auth',
   gapi_key: 'AIzaSyCFpQE6ti9wk3PeeKTprP42AGoB-BxzZkc',
   session_dim: 2,
   session_age: 30,
   base_url: '/app',
   avatar_width: 150,
   avatar_height: 150,
   sign_width: 80,
   sign_height: 60,
}

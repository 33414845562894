import Vue from 'vue'
import VueRouter from 'vue-router'
import CFG from '../config'

Vue.use(VueRouter)

const routes = [
   { path: '/login', component: () => import('@/views/Login') },
   {
      path: '/old',
      name: 'Old',
      component: () => import(/* webpackChunkName: "old" */ '../components/Compat.vue'),
      children: [
         {
           path: 'users',
           name: 'Users Management',
           component: () => import('@/views/User'),
         },
      ]
   },
   {
      path: '/',
      component: () => import(/* webpackChunkName: "old" */ '../components/Layout.vue'),
      children: [
         {name: 'Home', path: '', component: () => import('@/views/Home')},
         {name: 'Users', path: 'users', component: () => import('@/views/User')},
         {name: 'Order', path: 'order', component: () => import('@/views/Order')},
         {name: 'Client', path: 'client', component: () => import('@/views/Client')},
         {name: 'Setting', path: 'settings/:mod', component: () => import('@/views/Settings')}
      ]
   }
]

const router = new VueRouter({
   mode: 'history',
   base: CFG.base_url,
   routes
})

export default router

import axios from 'axios';
// import cookies from 'js-cookie'
import CFG from '../config';

// const token = cookies.get(CFG.tokenKey);
// if(token) {
//   console.log('token exist');
//   axios.defaults.headers.common[CFG.tokenHeaderKey] = token;
// }

export function login(data) {
   return new Promise((res, rej) => {
      axios.post(CFG.api_url +'/login', data).then(({data}) => {
         res(data);
      }).catch(e => {
         console.log('login api err:', e);
         rej(e);
      })
   });
}

export function changePwd(data) {
   return new Promise((res, rej) => {
      axios.post(CFG.api_url +'/renew-password', data).then(({data}) => {
         res(data);
      }).catch(e => {
         console.log('login api err:', e);
         rej(e);
      })
   });
}

export function create(data) {
   return new Promise((res, rej) => {
      axios.post(CFG.api_url +'/user', data).then(({data}) => {
         res(data);
      }).catch(e => {
         console.log('create-user api err:', e);
         rej(e);
      })
   });
}

export function get(filter) {
   return new Promise((res, rej) => {
      let url = CFG.api_url +'/user?';
      for(let i in filter) {
         url += `&${i}=${filter[i]}`;
      }
      axios.get(url).then(({data}) => {
         res(data);
      }).catch(e => {
         console.log('get user api err:', e);
         rej(e);
      })
   });
}

export function detail(id) {
   return new Promise((res, rej) => {
      axios.get(CFG.api_url +'/user/'+ id).then(({data}) => {
         res(data);
      }).catch(e => {
         console.log('create-user api err:', e);
         rej(e);
      })
   });
}

export function edit(id, data) {
   return new Promise((res, rej) => {
      axios.put(CFG.api_url +'/user/'+ id, data).then(({data}) => {
         res(data);
      }).catch(e => {
         console.log('create-user api err:', e);
         rej(e);
      })
   });
}

export function reset(uid) {
   return new Promise((res, rej) => {
      axios.post(CFG.api_url +`/user/${uid}/reset-password`).then(({data}) => {
         res(data);
      }).catch(e => {
         console.log('login api err:', e);
         rej(e);
      })
   });
}

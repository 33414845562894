import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
   state: {
      drawer: null,
      color: 'success',
      alert: [],
      image: 'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-2.32103624.jpg'
   },
   mutations: {
      setDrawer: (state, payload) => (state.drawer = payload),
      addAlert: (state, title, text, type) => {
         state.alert.push(typeof title === 'object' ? title : {title,text,type});
      },
      delAlert: (state, idx) => (state.alert.splice(idx, 1)),
      setImage: (state, payload) => (state.image = payload),
      setColor: (state, payload) => (state.color = payload),
      toggleDrawer: state => (state.drawer = !state.drawer)
   },
   actions: {
      alert({state, commit}, {timer,...data}) {
         commit('addAlert', data);
         if(timer) setTimeout(() => {
            commit('delAlert', state.alert.indexOf(data))
         }, timer);
      }
   },
   modules: {
      auth
   }
})
